var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      {
        staticClass: "home-page",
        staticStyle: { "background-color": "#090909" },
      },
      [
        _c("zui-header"),
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                  "background-color": "#201e1f",
                  "padding-top": "200px",
                  "padding-bottom": "80px",
                },
              },
              [
                _c("img", {
                  staticClass: "top-img",
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/values/top_banner.jpg",
                  },
                }),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  color: "#fff",
                  "font-size": "18px",
                  padding: "0px 0 80px",
                  "background-color": "#201e1f",
                  "font-weight": "100",
                },
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("value_page.continue")))])]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                  padding: "12% 0 24%",
                  "flex-direction": "column",
                  "align-items": "center",
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/logo_studio1.png",
                    width: "20%",
                    height: "40%",
                  },
                }),
                _c("div", { staticClass: "p-text" }, [
                  _vm._v(" " + _vm._s(_vm.$t("value_page.des_1")) + " "),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  display: "flex",
                  "justify-content": "center",
                  padding: "40px 0px 24%",
                  margin: "-300px 0",
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://www.achencybe.com/img/pc/others/picture1_price.png",
                    width: "100%",
                    height: "100%",
                  },
                }),
              ]
            ),
            _vm._l(_vm.banner, function (item) {
              return _c(
                "div",
                {
                  key: item.title,
                  staticClass: "values-banner",
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                    padding: "80px 0px 0",
                  },
                },
                [
                  _c("img", {
                    attrs: { src: item.img, width: "100%", height: "100%" },
                  }),
                  _c("div", [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t(item.title))),
                    ]),
                    _c("div", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(_vm.$t(item.subTitle))),
                    ]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }