<template>
  <Page>
    <div class="home-page" style="background-color:#090909;">
      <zui-header />
      <div>
        <div
          style="width:100%;display: flex;justify-content: center;background-color: #201e1f;padding-top:200px; padding-bottom:80px">
          <img src="https://www.achencybe.com/img/pc/values/top_banner.jpg" class="top-img">
        </div>
        <div style="color:#fff;font-size:18px;padding:0px 0 80px;background-color: #201e1f;font-weight:100">
          <span>{{$t('value_page.continue')}}</span>
        </div>
        <div style="width:100%;display: flex;justify-content: center;padding:12% 0 24%;flex-direction: column;align-items: center">
<!--          <img src="https://www.achencybe.com/img/pc/values/4-2.png" width="20%" height="40%" />-->
          <img src="https://www.achencybe.com/img/pc/logo_studio1.png" width="20%" height="40%" />
          <div class="p-text">
            {{$t('value_page.des_1')}} </div>
        </div>
        <div style="width:100%;display: flex;justify-content: center;padding:40px 0px 24%;margin:-300px 0;">
          <img src="https://www.achencybe.com/img/pc/others/picture1_price.png" width="100%" height="100%" />
        </div>

        <div style="width:100%;display: flex;justify-content: center;padding:80px 0px 0;" class="values-banner"
             v-for="item in banner" :key="item.title">
          <img :src="item.img" width="100%" height="100%" />
          <div>
            <div class="title">{{ $t(item.title) }}</div>
            <div class="sub-title">{{ $t(item.subTitle) }}</div>
          </div>
        </div>

        <!--        <div style="width:100%;display: flex;justify-content: center;padding:40px 0px 0;">-->
        <!--          <img src="../../../assets/images/other/value_2.png" width="100%" height="100%" />-->
        <!--        </div>-->

        <!--        <div style="width:100%;display: flex;justify-content: center;background-color: #201e1f;padding-bottom:120px">-->
        <!--          <img src="../../../assets/images/other/value_3.png" width="100%" height="100%" />-->
        <!--        </div>-->
      </div>
      <zui-footer />
    </div>
  </Page>

</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import Util from "../../../Util";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {
      banner: [
        {
          title: "value_page.des_2",
          subTitle: "value_page.des_3",
          img: "https://www.achencybe.com/img/pc/values/4-3.jpg"
        },
        {
          title: "value_page.des_4",
          subTitle: "value_page.des_5",
          img: "https://www.achencybe.com/img/pc/values/4-4.jpg"
        },
        {
          title: "value_page.des_6",
          subTitle: "value_page.des_7",
          img: "https://www.achencybe.com/img/pc/values/4-5.jpg"
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.top-img {
  box-shadow: -6px 20px 20px 4px #000;
  width: 42%;
}
.p-text{
  font-size:18px;
  color:#fff;
  width:40%;
  max-width:800px;
  line-height: 2;
  margin-top:60px;
}

.values-banner {
  position: relative;
  color: #fff;

  img {
    width: 100%;
  }

  & > div {
    position: absolute;
    bottom: 40px;
    width: 60%;
    max-width: 1000px;

    .title {
      font-size: 32px;
      text-align: left;
      margin-bottom: 10px;
    }

    .sub-title {
      line-height: 1.8;
      font-size: 18px;
      text-align: justify;
    }
  }
}

.values-banner:last-child {
  margin-bottom: 200px !important;
}


@media (max-width: 1500px) {
  .top-img {
    width: 50%;
  }
  .p-text{
    font-size:17px;
    margin-top:30px;
  }
  .values-banner {

    & > div {

      .title {
        font-size: 24px;
      }

      .sub-title {
        font-size: 14px;
      }
    }
  }
}
</style>
